import React from 'react';
import Tee from '../svg/tee.svg';
import Jumper from '../svg/jumper.svg';
import Accessory from '../svg/accessory.svg';
import Hoodie from '../svg/hoodie.svg';
import { Link } from 'gatsby';
import Seo from '../components/SEO';
import useSound from 'use-sound';
import Click from '../sounds/click.mp3';

function CollectionsPage() {
  const [click] = useSound(Click, { volume: 0.1 });

  function Category({ title, img, link, wip }) {
    return (
      <Link
        to={link}
        onClick={click}
        className={`${
          wip ? 'blur-sm' : ''
        } flex flex-col items-center justify-around h-full p-16`}
      >
        {img}
        <p className="flex flex-col items-center mt-16 font-serif text-2xl font-bold">
          {title}
        </p>
      </Link>
    );
  }

  return (
    <section
      className="mt-16 text-primary md:mt-20 lg:mt-12"
    >
      <Seo pageTitle="categories" />
      <div className="flex flex-row items-center justify-between w-full text-white bg-primary">
        <h1 className="p-4 text-4xl font-bold md:text-4xl">categories</h1>
        <Link className="p-4" to="/en/">
          see all
        </Link>
      </div>
      <div className="flex flex-wrap products">
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Category
            img={<Tee height="100%" width="80%" />}
            title="tee"
            link="/en/categories/t-shirt"
            wip={false}
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Category
            img={<Hoodie height="100%" width="80%" />}
            title="hoodies"
            link="/en/categories/hoodie"
            wip={false}
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Category
            img={<Jumper height="100%" width="80%" />}
            title="jumper"
            link="/en/categories/jumpers"
            wip={false}
          />
        </div>
        <div className="product text-primary hover:border-primary md:w-2/4">
          <Category
            img={<Accessory height="100%" width="80%" />}
            title="accessories"
            link="/wip"
            wip={true}
          />
        </div>
      </div>

      <div className="flex flex-col items-center">
        <h1 className="flex items-center h-screen text-4xl font-bold text-primary">
          more coming soon
        </h1>
      </div>
    </section>
  );
}

export default CollectionsPage;
